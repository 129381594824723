@import '@styles/_variables.scss';

* {
  .mat-mdc-form-field-error {
    color: var(--danger-color) !important;
  }

  .mat-mdc-select-trigger {
    flex-direction: row-reverse;

    .mat-mdc-select-value {
      margin-left: $s10;
    }

    .mat-mdc-select-arrow-wrapper {
      margin-top: $s4;
      margin-left: -$s15;

      .mat-mdc-select-arrow {
        width: 1.125rem;
        height: 1.125rem;
        background: url('@assets/images/icons/arrow-down.svg');
        background-repeat: no-repeat;

        svg {
          display: none;
        }
      }
    }
  }

  .cdk-overlay-dark-backdrop {
    background-color: var(--backdrop) !important;
  }

  .rounded-md {
    .mdc-text-field--outlined {
      color: var(--neutral-variant-40) !important;
      font-size: var(--body-medium-font-size) !important;
      font-weight: var(--font-weight-medium) !important;

      --mat-form-field-container-height: 40px;
      --mat-form-field-container-vertical-padding: 8px;
      --mdc-outlined-text-field-outline-color: var(--primary-90);
      --mdc-outlined-text-field-container-shape: 28px;
    }
  }

  mat-label,
  input,
  .mat-mdc-form-field-required-marker.mdc-floating-label--required {
    color: var(--on-surface-variant) !important;
  }

  .mdc-text-field--outlined {
    --mdc-outlined-text-field-outline-color: var(--outline-variant);
    --mdc-outlined-text-field-container-shape: 8px;

    &:not(.mdc-text-field--disabled) {
      .mdc-text-field__input {
        color: var(--on-surface);
        font-size: var(--body-large-font-size);
        line-height: var(--body-large-line-height);
      }
    }
  }

  .mdc-checkbox__native-control {
    &:enabled:checked ~ .mdc-checkbox__background,
    &:enabled:indeterminate ~ .mdc-checkbox__background {
      background-color: var(--primary) !important;
      border-color: var(--primary) !important;
    }
  }

  .cdk-overlay-pane {
    &:not(.mat-mdc-select-panel-above) {
      .mat-mdc-select-panel {
        margin-top: $s10;
        padding: $s0;
        width: 12.5rem;
        min-width: 7rem;
        max-width: 17.5rem;
        background-color: var(--white);
        border-radius: 0.5rem !important;
        border: 1px solid var(--primary-90);
      }
    }
  }

  .mat-mdc-option {
    &.mdc-list-item--selected {
      &:not(.mdc-list-item--disabled) {
        &:not(.mat-mdc-option-multiple) {
          background-color: var(--secondary) !important;

          .mat-pseudo-checkbox-minimal {
            &.mat-pseudo-checkbox-checked {
              &::after {
                color: var(--secondary-60);
              }
            }
          }
        }
      }
    }
  }
}
