@import '@styles/_variables.scss';

:root {
  --spacing-x-small: 0.5rem; /* 8px */
  --spacing-small: 0.75rem; /* 12px */
  --spacing-xl: 2.5rem; /* 40px */
  --spacing-wide: 4.5rem; /* 72px */
}

.sp-p {
  &-xl {
    padding: var(--spacing-xl);
  }
}

.sp-px {
  &-wide {
    padding-left: var(--spacing-wide);
    padding-right: var(--spacing-wide);
  }
}

.sp-mr {
  &-x-small {
    margin-right: var(--spacing-x-small) !important;
  }
}
