@import '@styles/_variables.scss';

.font {
  &-color {
    &-success {
      color: var(--on-secondary-container);
    }

    &-error {
      color: var(--danger-color);
    }

    &-info {
      color: var(--on-primary-container);
    }

    &-warn {
      color: var(--on-tertiary-container);
    }
  }
}

.p-toast-message {
  &-success {
    border-color: var(--secondary-60);

    .p-toast-message-content {
      @extend .font-color-success;
      background-color: var(--on-secondary);

      .p-toast-message-icon,
      .p-toast-icon-close {
        @extend .font-color-success;
      }
    }
  }

  &-error {
    border-color: var(--on-error-container);

    .p-toast-message-content {
      @extend .font-color-error;
      background-color: var(--error-container);

      .p-toast-message-icon,
      .p-toast-icon-close {
        @extend .font-color-error;
      }
    }
  }

  &-info {
    border-color: var(--on-primary-container);

    .p-toast-message-content {
      @extend .font-color-info;
      background-color: var(--inverse-primary);

      .p-toast-message-icon,
      .p-toast-icon-close {
        @extend .font-color-info;
      }
    }
  }

  &-warn {
    border-color: var(--on-tertiary-container);

    .p-toast-message-content {
      @extend .font-color-warn;
      background-color: var(--tertiary-container);

      .p-toast-message-icon,
      .p-toast-icon-close {
        @extend .font-color-warn;
      }
    }
  }
}
