:root {
  --primary: #54565b; /* $grey */
  --primary-20: #2e3035; /* $gunmetal */
  --primary-50: #75777c; /* $boulder */
  --primary-60: #8f9096; /* $mountain-mist */
  --primary-70: #aaabb1;
  --primary-90: #e1e2e8; /* $platinum */
  --primary-95: #f0f0f6; /* $porcelain */
  --primary-99: #fdfcff;
  --outline-variant: #c3c6cf;

  --neutral-variant-40: #5f5e5e; /* $carbon-grey */
  --neutral-variant-80: #c9c6c5;
  --neutral-variant-95: #f4f0ef;

  --neutral-60: #929090; /* $platinumGray */
  --neutral-70: #adaaaa;
  --neutral-98: #fcf8f8; /* $vista-white */

  --secondary: #a6dc6e; /* $pale-olive-green */
  --secondary-60: #6d9e39; /* $flat-green */
  --secondary-70: #86ba51;
  --on-secondary: #cbeda5; /* $light-sage */
  --on-secondary-container: #0e2000; /* $pine-tree */

  --tertiary: #6d5677;
  --tertiary-40: #615d60;
  --tertiary-container: #f6d9ff;
  --on-tertiary-container: #261430;

  --on-surface: #191c20; /* $darkJungleGreenText */
  --overlay-012: rgba(29, 27, 32, 0.12); /* $darkJungleGreen #1d1b20 */
  --on-surface-variant: #43474e;

  --success-color: #388e3c;

  --danger-color: #ba1a1a; /* $red */
  --danger-color-light: #fd0d0d40; /* $red-light */
  --error-container: #ffdad6;
  --on-error-container: #410002;

  --on-primary-container: #001c39;
  --inverse-primary: #a4c9fe;

  --black: #000000; /* $black */
  --white: #ffffff; /* $white */

  --body-bg-color: #f7f7f7; /* $desert-storm */
  --border-color: var(--primary-70); /* $aluminium */
  --border-color-dark: #c9c6c5; /* $silver */
  --border-color-light: #c5c6cc; /* $silverLight */

  --backdrop: rgba(0, 0, 0, 0.65) !important;

  --skeleton-30: #d9d9d9;
}
