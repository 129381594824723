.admin {
  h1 {
    color: var(--primary-20);
    font-size: var(--display-small-font-size);
    font-weight: var(--font-weight-semi-bold);
    line-height: var(--display-small-line-height);
  }

  .image {
    img {
      padding: $s5;
      width: $sr10;
      border-radius: var(--border-radius-small);
      border: $s1 solid var(--neutral-variant-95);
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      gap: var(--spacing-x-small);
    }

    .label {
      color: var(--primary-60) !important;
      font-size: var(--body-small-font-size);
      font-weight: var(--font-weight-medium);
      line-height: var(--body-small-line-height);
    }
  }

  .bg {
    &-black-custom {
      .image {
        app-image {
          img {
            background: linear-gradient(
                0deg,
                rgba(0, 0, 0, 0.2) 0%,
                rgba(0, 0, 0, 0.2) 100%
              ),
              linear-gradient(180deg, rgba(0, 0, 0, 0) 70%, #000 100%),
              linear-gradient(0deg, rgba(0, 0, 0, 0) 70%, #000 120%),
              linear-gradient(270deg, rgba(0, 0, 0, 0) 80%, #000 110%);
          }
        }
      }
    }
  }

  .logos-branding {
    img {
      padding: 1rem;
    }
  }

  app-line {
    .line {
      margin: 1rem 0 2rem !important;
    }
  }

  .mat-mdc-form-field-subscript-wrapper {
    margin-bottom: 0;
  }

  .mat-mdc-table {
    background-color: transparent !important;
  }

  .cursor {
    &-move {
      cursor: move;
    }

    &-pointer {
      cursor: pointer;
    }
  }

  .cdk {
    &-drop-list-dragging {
      .cdk-drag-placeholder {
        &.bg-white {
          .cdk-bg {
            padding: 1rem;
            background: var(--primary-90) !important;
            border-radius: 20px;
          }
        }
      }
    }
  }
}

.p-fileupload-buttonbar {
  padding: $s0;
  width: 100%;
  background-color: transparent;
  border: 0;
  display: flex;
}

.p-button {
  background-color: transparent;
  color: var(--neutral-variant-40) !important;
  font-size: var(--label-large-font-size);
  font-weight: var(--font-weight-medium);
  border: 1px solid var(--primary-90) !important;
  border-radius: var(--border-radius-xxl31);
  display: flex;
  justify-content: center;
  align-items: center;
}

.p-fileupload-content {
  display: none;
}

.p-orderlist-controls {
  display: none;
}

.p-orderlist-list {
  padding: $s0;
}

.p-orderlist-list-container {
  border: 0;
}
