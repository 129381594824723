// Size
$s0: 0;
$s1: 1px;
$s4: 4px;
$s5: 5px;
$s8: 8px;
$s10: 10px;
$s11: 11px;
$s12: 12px;
$s14: 14px;
$s15: 15px;
$s16: 16px;
$s18: 18px;
$s20: 20px;
$s23: 23px;
$s24: 24px;
$s30: 30px;
$s32: 32px;
$s40: 40px;
$s44: 44px;
$s56: 56px;
$s60: 60px;
$s72: 72px;
$s100: 100px;

// Size rem
$sr1: 1rem;
$sr1-5: 1.5rem;
$sr2: 2rem;
$sr2-5: 2.5rem;
$sr8: 8rem;
$sr10: 10rem;

// Size vh
$svh60: 60vh;
$svh100: 100vh;

// Size vw
$svw50: 50vw;

// Size %
$svp25: 25%;
$svp50: 50%;
$svp65: 65%;
$svp59: 59%;
$svp100: 100%;
