@import '@styles/_variables.scss';

@mixin width($width) {
  width: $width !important;
}

.w {
  &-65 {
    @include width($svp65);
  }

  @media (min-width: 769px) {
    &-md {
      &-25 {
        @include width($svp25);
      }

      &-50 {
        @include width($svp50);
      }

      &-100 {
        @include width($svp100);
      }
    }
  }
}
