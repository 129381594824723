@import '@angular/material/prebuilt-themes/azure-blue.css';
@import '@styles/_custom-material.scss';
@import '@styles/_variables.scss';
@import '@styles/_typography.scss';
@import '@styles/_colors.scss';
@import '@styles/_background.scss';
@import '@styles/_spacing.scss';
@import '@styles/_border.scss';
@import '@styles/_toast.scss';
@import '@styles/_size.scss';
@import '@styles/_admin.scss';

* {
  letter-spacing: -0.015rem !important;
  box-shadow: none !important;

  &:not(.icon-custom) {
    font-family: var(--font-family-primary) !important;
  }
}

html {
  font-size: 100%;
  font-weight: var(--font-weight-regular);
}

body {
  color: var(--black);
}

hr {
  border-bottom-color: var(--primary-95);
}

ul {
  line-height: var(--body-large-line-height);
  list-style: none;
}

.line {
  &.line {
    &-before {
      &::before {
        content: '';
        width: 100%;
        display: block;
        border-bottom: $s1 solid var(--primary-95);
      }
    }
  }
}

button {
  &.account,
  &.cart {
    padding: $s10;
    margin: $s0;
    min-width: unset;
    height: unset;
    line-height: $s0;
  }
}

.footer-menu {
  h5,
  a {
    font-size: var(--body-medium-font-size);
    font-weight: var(--font-weight-medium);
    line-height: var(--body-medium-line-height);
  }

  a {
    color: var(--primary-60);
  }
}

.base-custom {
  height: 100%;
  display: flex;
  flex-direction: column;

  &-content {
    margin-bottom: $s20;
    height: calc(88vh - 70px);
    flex-grow: 1;
    overflow-y: auto;

    @media (max-width: 768px) {
      height: calc(75vh - 35px);
    }

    @media (min-width: 2560px) and (min-height: 955px) {
      height: calc(88vh - 35px);
    }
  }
}

.active {
  color: var(--secondary-70);
}

.expired {
  color: var(--neutral-variant-80);
}

#header {
  min-height: 500px;
}

#como-funciona {
  min-height: 300px;
}

#conheca-os-modelos {
  min-height: 470px;
}

#vantagens {
  min-height: 300px;
}

#quem-somos {
  min-height: 560px;
}

@media (max-width: 768px) {
  .container {
    padding: 0 $sr2;
  }
}
