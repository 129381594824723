$inter-font: 'Inter';

:root {
  --font-family-primary: 'Inter', sans-serif;

  --font-weight-regular: 400;
  --font-weight-medium: 500;
  --font-weight-semi-bold: 600;
  --font-weight-bold: 700;

  --display-large-font-size: 3.563rem; /* 57px */
  --display-large-line-height: 4rem; /* 64px */
  --display-medium-font-size: 2.813rem; /* 45px */
  --display-medium-line-height: 3.25rem; /* 52px */
  --display-small-font-size: 2.25rem; /* 36px */
  --display-small-line-height: 2.75rem; /* 44px */

  --headline-large-font-size: 2rem; /* 32px */
  --headline-large-line-height: 2.5rem; /* 40px */
  --headline-medium-font-size: 1.75rem; /* 28px */
  --headline-medium-line-height: 2.25rem; /* 36px */
  --headline-small-font-size: 1.5rem; /* 24px */
  --headline-small-line-height: 2rem; /* 32px */

  --title-large-font-size: 1.375rem; /* 22px */
  --title-large-line-height: 1.75rem; /* 28px */
  --title-medium-font-size: 1rem; /* 16px */
  --title-medium-line-height: 1.5rem; /* 24px */
  --title-small-font-size: 0.875rem; /* 14px */
  --title-small-line-height: 1.25rem; /* 20px */

  --body-large-font-size: 1rem; /* 16px */
  --body-large-font-size-2: 1.1rem;
  --body-large-line-height: 1.5rem; /* 24px */
  --body-medium-font-size: 0.875rem; /* 14px */
  --body-medium-line-height: 1.25rem; /* 20px */
  --body-small-font-size: 0.75rem; /* 12px */
  --body-small-line-height: 1rem; /* 16px */

  --label-large-font-size: 0.875rem; /* 14px */
  --label-large-line-height: 1.25rem; /* 20px */
  --label-medium-font-size: 0.75rem; /* 12px */
  --label-medium-line-height: 1rem; /* 16px */
  --label-small-font-size: 0.688rem; /* 11px */
  --label-small-line-height: 1rem; /* 16px */

  --font-size-30: 1.875rem; /* 30px */
  --font-size-xs: var(--body-small-font-size); /* 12px */
  --font-size-sm: var(--body-medium-font-size); /* 14px */
  --font-size-md: var(--body-large-font-size); /* 16px */
  --font-size-md-2: var(--body-large-font-size-2);
  --font-size-lg: var(--title-large-font-size); /* 22px */
  --font-size-xl-sm: var(--headline-medium-font-size); /* 28px */
  --font-size-xl: var(--headline-small-font-size); /* 24px */
  --font-size-2xl: var(--font-size-30); /* 30px */
  --font-size-3xl: var(--display-small-font-size); /* 36px */
  --font-size-4xl: var(--display-medium-font-size); /* 45px */

  --line-height-xs: var(--body-small-line-height); /* 16px */
  --line-height-sm: var(--title-small-line-height); /* 20px */
  --line-height-md: var(--title-medium-line-height); /* 24px */
  --line-height-lg: var(--title-large-line-height); /* 28px */
  --line-height-xl: var(--headline-small-line-height); /* 32px */
  --line-height-2xl: var(--headline-medium-line-height); /* 36px */
  --line-height-3xl: var(--headline-large-line-height); /* 40px */
  --line-height-4xl: var(--display-medium-line-height); /* 52px */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--black);
}

h1 {
  font-size: var(--display-large-font-size);
  font-weight: var(--font-weight-bold);
  line-height: var(--display-large-line-height);
}

h2 {
  font-size: var(--title-large-font-size);
  font-weight: var(--font-weight-regular);
  line-height: var(--title-large-line-height);
}

p {
  color: var(--primary-50);
  font-size: var(--label-large-font-size);
  line-height: var(--label-large-line-height);
}

.fs {
  &-xs {
    font-size: var(--font-size-xs);
  }

  &-sm {
    font-size: var(--font-size-sm);
  }

  &-md {
    font-size: var(--font-size-md);
  }

  &-md-2 {
    font-size: var(--font-size-md-2);
  }

  &-lg {
    font-size: var(--font-size-lg);
  }

  &-xl {
    font-size: var(--font-size-xl);

    &-sm {
      font-size: var(--font-size-xl-sm);
    }
  }

  &-2xl {
    font-size: var(--font-size-2xl);
  }

  &-3xl {
    font-size: var(--font-size-3xl);
  }

  &-4xl {
    font-size: var(--font-size-4xl);
  }
}

.lh {
  &-xs {
    line-height: var(--line-height-xs) !important;
  }

  &-sm {
    line-height: var(--line-height-sm) !important;
  }

  &-md {
    line-height: var(--line-height-md) !important;
  }

  &-lg {
    line-height: var(--line-height-lg) !important;
  }

  &-xl {
    line-height: var(--line-height-xl) !important;
  }

  &-2xl {
    line-height: var(--line-height-2xl) !important;
  }

  &-3xl {
    line-height: var(--line-height-3xl) !important;
  }

  &-4xl {
    line-height: var(--line-height-4xl) !important;
  }
}

.fw {
  &-500 {
    font-weight: var(--font-weight-medium);
  }

  &-600 {
    font-weight: var(--font-weight-semi-bold);
  }

  &-700 {
    font-weight: var(--font-weight-bold);
  }
}

.text {
  &-black {
    color: var(--black) !important;
  }

  &-primary {
    color: var(--primary) !important;
  }

  &-primary-color {
    color: var(--on-secondary-container) !important;
  }

  &-flat-green {
    color: var(--secondary-60) !important;
  }

  &-boulder {
    color: var(--primary-50);
  }

  &-aluminium {
    color: var(--primary-70);
  }

  &-gunmetal {
    color: var(--primary-20);
  }

  &-mountain-mist {
    color: var(--primary-60);
  }

  &-grey {
    color: var(--primary);
  }

  &-neutral-70 {
    color: var(--neutral-70);
  }
}

.text-decoration {
  &-underline {
    text-decoration: underline;
  }
}
