:root {
  --border-radius-x-small: 0.25rem; /* 4px */
  --border-radius-small: 0.5rem; /* 8px */
  --border-radius-3xl: 0.75rem; /* 12px */
  --border-radius-medium: 1rem; /* 16px */
  --border-radius-large: 1.5rem; /* 24px */
  --border-radius-large-xl: 2rem; /* 28px */
  --border-radius-xl: 2rem; /* 32px */
  --border-radius-xxl: 3rem; /* 48px */
  --border-radius-full: 9999px; /* full circle */
  --border-radius-xxl31: 31.25rem;
}

.border {
  &-white {
    border-color: var(--white);
  }
}
